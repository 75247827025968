<template>
  <div class="msg-alert ly-wrap">

    <div class="nav-box">
      <div class="nav-item on">报警记录</div>
      <div class="nav-item" @click="goWay('/msg/notice')">系统公告</div>
    </div>

    <div class="pump-condition">
      <div class="lt-btn" @click="tjcxPOP=true">条件筛选</div>
      <div class="rt-btn" @click="handleAllRead"><span class="icon"></span> 全部已读</div>
    </div>

    <div class="ly-center">
      <div class="alert-box"
           v-infinite-scroll="loadMore"
           infinite-scroll-disabled="isMoreLoading"
           infinite-scroll-distance="10">
        <div class="alert-item" v-for="item in msgData" :key="item.id">
          <div class="state">
            <i v-if="item.priority=='I'" class="red">特别严重</i>
            <i v-if="item.priority=='II'" class="red">严重</i>
            <i v-if="item.priority=='III'" class="orange">较重</i>
            <i v-if="item.priority=='IV'">一般</i>
            <div class="rt-btn" v-if="item.readFlag == '0'" @click="handleRead(item.anntId)"><span class="icon"></span>标为已读
            </div>
            <div class="rt-btn already" v-else>已读</div>
          </div>
          <div class="title">{{ item.titile }}</div>
          <div class="info-box">
            <div class="belonging">所属于泵站:{{ item.busId | pumpFormat(pumpList) }}</div>
            <div class="info">{{ item.msgContent }}</div>
            <div class="time">{{ item.sendTime }}</div>
          </div>
        </div>
        <div class="list-tips" v-if="isLoading"> 加载中...</div>
        <div class="list-tips" v-if="noMore">没有更多了~</div>
      </div>

    </div>

    <ly-foot-tab footOn="msg"></ly-foot-tab>

    <!--  条件查询  -->
    <mt-popup v-model="tjcxPOP" class="ly-pop-wrap tjcx-wrap" popup-transition="popup-fade">
      <div class="pop-body">
        <mt-checklist
            v-model="query.priority"
            :options="options">
        </mt-checklist>
        <div class="time-item">
          <div class="txt">开始日期</div>
          <input class="time-pick" @click="openPicker('start')" type="text"
                 v-model="query.startTime">
        </div>
        <div class="time-item">
          <div class="txt">结束日期</div>
          <input class="time-pick" @click="openPicker('end')" type="text"
                 v-model="query.endTime">
        </div>

      </div>
      <div class="pop-foot">
        <div class="cancel" @click="tjcxPOP = false">取消</div>
        <div class="sure" @click="sureCondition">确定</div>
      </div>
    </mt-popup>
    <mt-datetime-picker
        ref="picker"
        type="date"
        :start-date="start"
        :end-date="end"
        @confirm="handleConfirm"
    >
    </mt-datetime-picker>
  </div>
</template>

<script>
import {putAlertMsgState, getPumpAlertMsgPage, putTypeAllMsgState, getPumpListByWaterId} from '../../api/api.js'
import moment from 'moment'
import {MessageBox, Toast} from 'mint-ui';
import {getSelectPump, getSelectWater} from "../../utils/auth";
import LyFootTab from "../../components/LyFootTab";

export default {
  name: 'PumpAlert',
  components: {LyFootTab},
  computed: {},
  filters: {
    pumpFormat(pump, pumpList) {
      if (!pump) {
        return ''
      }
      for (const item of pumpList) {
        const {id, name} = item
        if (id === pump) {
          return name
        }
      }
    },
  },
  data() {
    return {
      start: moment().add(-3, 'years').toDate(),
      end: moment().toDate(),
      resultData: {},
      msgData: [],
      pumpList: [],// 泵站列表
      selectPump: {},// 用户选择的泵站
      tjcxPOP: false,// 条件查询弹框
      options: [
        {label: '非常严重', value: 'I',},
        {label: '严重', value: 'II',},
        {label: '较重', value: 'III',},
        {label: '一般', value: 'IV',},
      ],
      query: {
        priority: [],
        startTime: '',
        endTime: '',
        pageNo: 0,
        pageSize: 10,
        msgCategory: 2
      },
      isMoreLoading: false, // 加载更多中
      isLoading: false,
      noMore: false,
      pickType: null,// 时间选择类型
    }
  },
  created() {
    this.loadBases()
    this.loadData()
  },
  methods: {
    async loadBases() {
      const selectWater = await getSelectWater()
      const body = {
        waterId: selectWater.id
      }
      getPumpListByWaterId(body).then(res => {
        this.pumpList = res.data.result
      })
    },
    openPicker(type) {
      this.pickType = type
      this.$refs.picker.open();
    },
    handleConfirm(data) {
      if (this.pickType == 'end') {
        this.query.endTime = moment(data).format('YYYY-MM-DD')
      } else {
        this.query.startTime = moment(data).format('YYYY-MM-DD')
      }
    },
    sureCondition() {
      this.tjcxPOP = false
      this.initData()
    },
    initData() {
      this.query.pageNo = 0
      this.msgData = []
      this.loadData()
    },
    async loadData() {
      this.query.pageNo += 1
      this.selectPump = await getSelectPump()
      const parameter = {
        priority: this.query.priority.join(','),
        startTime: this.query.startTime,
        endTime: this.query.endTime,
        pageNo: this.query.pageNo,
        pageSize: this.query.pageSize,
        msgCategory: this.query.msgCategory,
      }
      getPumpAlertMsgPage(parameter).then(res => {
        this.moreList = res.data.result.records
        this.resultData = res.data.result
        let _this = this
        if (this.moreList.length == 0) {
          this.noMore = true;
          this.isLoading = false;
        } else {
          this.query.pageNo = res.data.result.current
          this.isLoading = false;
          this.moreList.forEach(function (item) {
            _this.msgData.push(item);
          })
        }
      })
    },
    loadMore() {
      this.isMoreLoading = true;
      this.isLoading = true;
      this.noMore = false;
      if (this.query.pageNo >= this.resultData.pages) {
        this.noMore = true;
        this.isLoading = false;
        this.isMoreLoading = false;
      } else {
        this.loadData()
        this.noMore = false;
        this.isMoreLoading = false;
      }
    },
    // 单条记录
    handleRead(id) {
      const body = {
        anntId: id
      }
      putAlertMsgState(body).then(res => {
        Toast("操作成功")
        this.initData()
      })
    },
    // 全部标记已读
    handleAllRead() {
      MessageBox.confirm('确定将消息全部标记为已读?').then(action => {
        const body = {
          msgCategory: '2'
        }
        putTypeAllMsgState(body).then(res => {
          Toast("操作成功")
          this.initData()
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
  },
}
</script>
<style>
.msg-alert .tjcx-wrap .mint-cell {
  display: inline-block;
}

.msg-alert .tjcx-wrap .mint-cell-wrapper {
  background: none;
}
</style>

<style scoped lang="less">

.msg-alert {
  .nav-box {
    height: 0.88rem;
    line-height: 0.88rem;
    display: flex;
    background-color: #DEEFFF;
    box-shadow: 0 0.04rem 0.02rem 0 rgba(0, 0, 0, 0.1);

    .nav-item {
      flex: 1;
      line-height: 0.88rem;
      text-align: center;
      font-size: 0.36rem;
      font-weight: bold;
      color: #333333;
    }

    .on {
      color: #FFFFFF;
      background: #0088FF;
    }
  }
}

.msg-alert {
  .rt-btn {
    font-size: 0.34rem;
    float: right;
    padding-right: 0.23rem;

    .icon {
      width: 0.44rem;
      height: 0.44rem;
      margin-right: 0.1rem;
      display: inline-block;
      background: url("~@/assets/img/choice.png") no-repeat center;
      background-size: 0.44rem;
      position: relative;
      top: 0.1rem;
    }

    .choose {
      background: url("~@/assets/img/choice-on.png") no-repeat center;
      background-size: 0.44rem;
    }
  }

  .pump-condition {
    width: 100%;
    height: 0.88rem;
    line-height: 0.88rem;
    border-bottom: 1px solid #E2E2E2;
    position: relative;
    color: #333333;

    .lt-btn {
      font-size: 0.28rem;
      width: 1.6rem;
      height: 0.6rem;
      text-align: center;
      line-height: 0.6rem;
      background: rgba(51, 51, 51, 0);
      border: 1px solid #0088FF;
      //box-shadow: 0 0.05rem 0.12rem 0 rgba(0, 136, 255, 0.18);
      border-radius: 10px;
      color: #0088FF;
      position: relative;
      top: 0.12rem;
      left: 0.23rem;
      float: left;
    }

    .already {
      color: #999999;
    }
  }

  .alert-box {
    padding-bottom: 1.3rem;

    .alert-item {
      width: 100%;
      height: auto;
      margin-top: 0.2rem;
      background: #FFFFFF;
      box-shadow: 0 0.03rem 0.03rem 0 rgba(0, 0, 0, 0.09);
      border-radius: 0.1rem;
      overflow: hidden;

      .state {
        height: 0.64rem;
        background: #F2F2F2;
        line-height: 0.64rem;

        i {
          width: 1.2rem;
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          //padding: 0.06rem 0.18rem;
          background: #0088FF;
          border-radius: 0.05rem;
          color: #FFFFFF;
          font-style: normal;
          display: inline-block;
          position: relative;
          left: 0.2rem;
        }

        .red {
          background: #D93636;
        }

        .yellow {
          background: #F4DC43;
        }

        .orange {
          background: #F2A50C;
        }
      }

      .title {
        width: 6.55rem;
        margin: 0 auto;
        height: auto;
        border-bottom: 1px solid #E2E2E2;
        font-size: 0.36rem;
        font-weight: bold;
        color: #333333;
        padding: 0.1rem 0;
      }

      .info-box {
        width: 6.55rem;
        margin: 0 auto;
        padding: 0.1rem 0 0.2rem;

        .belonging {
          color: #999999;
          line-height: 0.6rem;
        }

        .info {
          font-size: 0.3rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.4rem;
        }

        .time {
          font-size: 0.24rem;
          font-weight: 400;
          color: #999999;
          line-height: 0.3rem;
          padding-top: 0.15rem;
        }
      }
    }

    .list-tips {
      line-height: 1rem;
      text-align: center;
      font-size: 0.28rem;
      color: #333333;
    }
  }

}

// 条件筛选弹框
.tjcx-wrap {
  .time-item {
    width: 4.8rem;
    margin: 0 auto;
    text-align: center;
    line-height: 0.8rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: #333333;

    .time-pick {
      width: 4.8rem;
      height: 0.56rem;
      line-height: 0.56rem;
      border-radius: 0.1rem;
      border: 1px solid #0088FF;
      box-shadow: 0 0.05rem 0.12rem 0 rgba(0, 136, 255, 0.18);
    }
  }
}
</style>

